<template>
  <div class="content-wrapper">
    <b-overlay
      :show="loading"
      rounded="lg"
      :opacity="0.8"
    >
      <div class="p-2 card">
        <b-row class="mb-3">
          <b-col
            md="9"
            sm="12"
          >
            <h1>Usuários Sesi</h1>
            <p>
              Para realizar uma busca, selecione o(s) filtros necessário(s) e clique
              no botão buscar:
            </p>
          </b-col>

          <b-col
            md="3"
            sm="12"
          >
            <b-button
              type="button"
              variant="custom"
              class="botao float-lg-right"
              @click="openModal('insert')"
            >
              <BIconPlusCircle />
              &nbsp; Incluir Novo Usuário
            </b-button>
          </b-col>
        </b-row>

        <section class="filtros mt-2">
          <validation-observer ref="formFilters">
            <b-form>
              <b-row>
                <b-col
                  xl="3"
                  md="6"
                >
                  <b-form-group
                    label="Nome"
                    label-for="nome"
                  >
                    <b-form-input
                      id="nome"
                      v-model="busca.nome"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  xl="3"
                  md="6"
                >
                  <b-form-group
                    label="CPF"
                    label-for="cpf"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="CPF"
                      rules="cpf"
                    >
                      <b-form-input
                        id="cpf"
                        v-model="busca.cpf"
                        v-mask="'###.###.###-##'"
                        autocomplete="off"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  xl="3"
                  md="6"
                >
                  <b-form-group
                    label="E-mail"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="E-mail"
                      rules="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="busca.email"
                        autocomplete="off"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  xl="3"
                  md="6"
                >
                  <b-form-group
                    label="Regional SESI"
                    label-for="regionais"
                  >
                    <v-select
                      id="regionais"
                      v-model="busca.regional"
                      :options="regioesSelect"
                      variant="custom"
                      item-text="descricao"
                      item-value="id_regiao"
                      label="descricao"
                      placeholder="Regionais SESI"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <div class="w-100 d-flex justify-content-center my-2">
                <b-button
                  class="mr-2"
                  type="reset"
                  variant="outline-secondary"
                  @click="limparFiltros"
                >
                  Limpar
                </b-button>

                <b-button
                  type="submit"
                  variant="custom"
                  @click.prevent="filtrarDadosUsuarios"
                >
                  Buscar
                </b-button>
              </div>
            </b-form>
          </validation-observer>

          <b-modal
            id="modal-usuario-admin"
            ref="modal-usuario-admin"
            v-model="mostrarModal"
            :title="getModalTitle"
            size="lg"
            centered
            :hide-footer="true"
            :no-close-on-esc="true"
            :no-close-on-backdrop="true"
            @close="esconderModal"
          >
            <UsuariosSesiForm
              :mode="formMode"
              :profiles-select="perfisSelect"
              :regions-select="regioesSelect"
              :modules-select="modulosSelect"
              :choose-user="chooseUser"
              @success="sucessoInserirAtualizar"
              @cancel="esconderModal"
            />
          </b-modal>
        </section>

        <div
          v-if="carregandoTabela"
          class="w-100 mt-5 d-flex flex-column align-items-center justify-content-center"
        >
          <b-spinner
            variant="custom"
            class="mb-1"
            label="Loading..."
          />
          <span>carregando...</span>
        </div>

        <b-alert
          variant="primary"
          :show="tabela.semDados"
          class="mt-5"
        >
          <div class="alert-body d-flex justify-content-center">
            <span class="text-primary">
              <strong class="text-primary">Nenhum registro encontrado.</strong>
              Realize novamente uma busca utilizando diferentes critérios.
            </span>
          </div>
        </b-alert>

        <b-alert
          variant="primary"
          :show="tabela.erroTabela"
          class="mt-5"
        >
          <div class="alert-body d-flex justify-content-center">
            <span class="text-primary">
              <strong
                class="text-primary"
              >Sistema de busca indisponível no momento.</strong>
            </span>
          </div>
        </b-alert>

        <section
          v-if="mostrarTabela"
          class="tabela-empresas"
        >
          <b-row class="mb-2">
            <b-col sm="12">
              <div
                class="d-flex flex-row align-items-center justify-content-between"
              >
                <b-col
                  sm="4"
                  class="pl-0"
                >
                  <label class="mr-50">Visualizar</label>
                  <b-form-select
                    id="amounlinesVisualizer"
                    v-model="dadosPaginacao.quantidadeLinhasPadrao"
                    class="w-50"
                    size="sm"
                    label="option"
                    :options="tabela.quantidadeLinhasVisualizar"
                    @input="atualizaQuantidadeVisualizar($event)"
                  />
                </b-col>
                <div
                  v-if="usuarioPodeAtivarInativar"
                  class="col-auto pr-0"
                >
                  <b-button
                    :disabled="!selecionarTodos && usuariosAtivarInativarStatus.length === 0"
                    variant="outline-primary"
                    @click="alterarStatusUsuariosEmMassa()"
                  >
                    Alterar Status dos Selecionados
                  </b-button>
                </div>
              </div>
            </b-col>
            <b-col
              sm="12"
              class="my-2"
            >
              <b-table
                id="listagemUsuariosSesi"
                responsive
                sticky-header="100%"
                :busy.sync="tabela.tabelaOcupada"
                :no-local-sorting="true"
                :fields="getFields"
                :items="tabela.items"
                @context-changed="ordenarTabela"
              >
                <template
                  #head(id_usuario)="data"
                >
                  <b-form-checkbox
                    v-model="selecionarTodos"
                    class="p-0"
                    @change="marcarOuDesmarcarTodos()"
                  />
                </template>

                <template #cell(id_usuario)="row">
                  <b-form-checkbox
                    v-if="usuarioPodeSerAtivadoInativado(row.item)"
                    v-model="usuariosAtivarInativarStatus"
                    class="p-0"
                    :value="row.item.id_usuario"
                  />
                </template>

                <template #cell(nome)="row">
                  <span>{{ row.value }}</span>
                </template>

                <template #cell(email)="row">
                  <span>{{ row.value }}</span>
                </template>

                <template #cell(regiao)="row">
                  <span
                    v-for="(regiao, index) in row.item.regiao"
                    :key="index"
                  >
                    <span v-if="index > 0">, </span>
                    <span>{{ regiao.descricao }}</span>
                  </span>
                </template>

                <template #cell(perfil)="row">
                  <span
                    v-for="(perfil, index) in row.item.perfil"
                    :key="index"
                  >
                    <span v-if="index > 0">, </span>
                    <span>{{ perfil.descricao }}</span>
                  </span>
                </template>

                <template #cell(modulo)="row">
                  <span
                    v-for="(modulo, index) in row.item.modulo"
                    :key="index"
                  >
                    <span v-if="index > 0">, </span>
                    <span>{{ modulo.descricao }}</span>
                  </span>
                </template>

                <template #cell(ativo)="row">
                  <b-form-checkbox
                    v-if="usuarioPodeSerAtivadoInativado(row.item)"
                    :checked="row.value"
                    class="custom-control-success"
                    name="check-button"
                    switch
                    @change="alterarStatusUsuarioUnico(row.item)"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right" />
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-if="!usuarioPodeSerAtivadoInativado(row.item)"
                    v-b-tooltip.hover
                    title="Não é possível alterar o status deste usuário"
                    :disabled="true"
                    :checked="row.value"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right" />
                  </b-form-checkbox>
                </template>

                <template #cell(acoes)="row">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon"
                    @click="openModalEdit(row.item)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </template>
              </b-table>
            </b-col>
            <b-col sm="12">
              <CustomPagination
                :paginacao="dadosPaginacao"
                @page-cliked="atualizarPaginaAtual"
              />
            </b-col>
          </b-row>
        </section>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BAlert,
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BIconPlusCircle,
  BRow,
  BSpinner,
  BTable,
  BOverlay,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { cpf, email } from '@validations'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import StatusField from '@/views/components/custom/inputs/StatusField.vue'
import { getUserData } from '@/auth/utils'
import UsuariosSesiForm from '@/views/pages/usuarios-sesi/UsuariosSesiForm.vue'
import 'animate.css'
import { modalGenericModel } from '@/libs/sweetalerts'
import {
  perfisPermitidosAdminMaster, perfisPermitidosAdminModulo,
  podeAtivarInativarPorAdminMaster,
  podeAtivarInativarPorAdminModulo,
} from '@/views/pages/usuarios-sesi/utils'
import { getArrayAttr } from '@core/helpers'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BIconPlusCircle,
    BFormSelect,
    BButton,
    BTable,
    BOverlay,
    CustomPagination,
    vSelect,
    StatusField,
    BAlert,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    UsuariosSesiForm,
    BFormCheckbox,
    VBTooltip,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      userData: getUserData(),

      email,
      cpf,

      loading: true,
      formMode: 'insert',

      busca: {
        nome: '',
        cpf: '',
        email: '',
        regional: '',
      },

      chooseUser: { id: '' },

      selecionarTodos: false,

      usuarios: [],
      usuariosAtivarInativarStatus: [],
      idsUsuarioDaPaginaAtual: [],
      perfisSelect: [],
      regioesSelect: [],
      modulosSelect: [],
      permiteAtualizarStatus: false,

      mostrarModal: false,

      mostrarTabela: false,
      carregandoTabela: false,

      dadosPaginacao: {
        paginaAtual: 1,
        totalLinhas: 1,
        daLinha: 1,
        ateALinha: 1,
        quantidadeLinhasPadrao: 10,
      },

      tabela: {
        semDados: false,
        erroTabela: false,
        tabelaOcupada: false,
        quantidadeLinhasVisualizar: [10, 25, 50, 100],
        ordem: 'asc',
        campoOrdenado: 'nome',
        items: [],
      },
    }
  },

  computed: {
    getModalTitle() {
      return this.formMode === 'insert'
        ? 'Incluir novo Usuário SESI'
        : 'Editar Usuário SESI'
    },

    getPortalUsuariosSesiAtivoAdminMasterAtualizar() {
      return this.$can('ATUALIZAR', 'PORTAL_USUARIOS_SESI_ATIVO_ADMIN_MASTER')
    },

    getPortalUsuariosSesiAtivoAdminModuloAtualizar() {
      return this.$can('ATUALIZAR', 'PORTAL_USUARIOS_SESI_ATIVO_ADMIN_MODULO')
    },

    usuarioPodeAtivarInativar() {
      return this.getPortalUsuariosSesiAtivoAdminMasterAtualizar || this.getPortalUsuariosSesiAtivoAdminModuloAtualizar
    },

    getFields() {
      return [
        this.usuarioPodeAtivarInativar
          ? { key: 'id_usuario', label: '#', class: 'text-center' }
          : null,
        { key: 'nome', label: 'NOME', sortable: true },
        { key: 'email', label: 'E-MAIL', sortable: true },
        { key: 'regiao', label: 'REGIONAL SESI' },
        { key: 'perfil', label: 'PERFIL' },
        { key: 'modulo', label: 'MÓDULO' },
        { key: 'ativo', label: 'Status' },
        { key: 'acoes', label: 'Ações', class: 'width-custom-column' },
      ]
    },
  },

  watch: {
    usuariosAtivarInativarStatus(value) {
      if (value.length === 0) {
        this.selecionarTodos = false
      }
    },
  },

  mounted() {
    this.dadosSelect()
  },

  methods: {
    async dadosSelect() {
      this.loading = true

      await this.retornaPerfis()
      await this.retornaRegioes()
      await this.retornaModulos()

      this.loading = false
    },

    PessoaVisualizada(id_pessoa_visualizada) {
      const form = {
        id_pessoa: this.userData.pessoa.id_pessoa,
        id_pessoa_visualizada,
      }
      this.$http.post(this.$api.PessoaVisualizada(), form)
    },

    buscarDadosUsuarios() {
      this.carregandoTabela = true

      this.tabela.erroTabela = false

      this.tabela.semDados = false
      this.tabela.tabelaOcupada = true

      this.$http
        .get(this.$api.UsuariosSesi, {
          params: this.lidarComParametrosBuscaUsuarios(),
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.data.length > 0) {
              this.mostrarTabela = true
              this.tabela.items = response.data.data
              this.tabela.tabelaOcupada = false
              this.carregandoTabela = false
              this.selecionarTodos = false
              this.usuariosAtivarInativarStatus = []
              this.IniciarPaginacao(response.data)
              return
            }

            this.carregandoTabela = false
            this.tabela.semDados = true
            this.mostrarTabela = false
          }
        })
        .catch(() => {
          this.carregandoTabela = false
          this.tabela.erroTabela = true
        })
    },

    filtrarDadosUsuarios() {
      this.$refs.formFilters.validate()
        .then(success => {
          if (success) {
            this.buscarDadosUsuarios()
          }
        })
    },

    sucessoInserirAtualizar() {
      this.mostrarModal = false

      this.buscarDadosUsuarios()
    },

    ordenarTabela(contextoTabela) {
      this.tabela.campoOrdenado = contextoTabela.sortBy
      this.tabela.ordem = contextoTabela.sortDesc ? 'desc' : 'asc'

      this.buscarDadosUsuarios()
    },

    lidarComParametrosBuscaUsuarios() {
      return {
        nome: this.busca.nome,
        cpf: this.busca.cpf.replace(/\D/g, ''),
        email: this.busca.email,
        regional: this.busca.regional
          ? this.busca.regional.id_regiao
          : '',
        columnName: this.tabela.campoOrdenado,
        columnOrder: this.tabela.ordem,
        perPage: this.dadosPaginacao.quantidadeLinhasPadrao,
        page: this.dadosPaginacao.paginaAtual,
      }
    },

    limparFiltros() {
      this.busca.nome = ''
      this.busca.cpf = ''
      this.busca.email = ''
      this.busca.regional = ''

      this.mostrarTabela = false

      this.limparPaginacao()
    },

    limparPaginacao() {
      this.dadosPaginacao = {
        paginaAtual: 1,
        totalLinhas: 1,
        daLinha: 1,
        ateALinha: 1,
        quantidadeLinhasPadrao: 10,
      }
    },

    IniciarPaginacao(dadosPaginacao) {
      this.dadosPaginacao.daLinha = dadosPaginacao.from
      this.dadosPaginacao.ateALinha = dadosPaginacao.to
      this.dadosPaginacao.totalLinhas = dadosPaginacao.total
      this.dadosPaginacao.paginaAtual = dadosPaginacao.current_page
      this.dadosPaginacao.quantidadeLinhasPadrao = dadosPaginacao.per_page
    },

    atualizarPaginaAtual(page) {
      this.dadosPaginacao.paginaAtual = page
      this.buscarDadosUsuarios()
    },

    atualizaQuantidadeVisualizar(event) {
      if (!event) {
        this.dadosPaginacao.quantidadeLinhasPadrao = 10
      }

      this.dadosPaginacao.paginaAtual = 1
      this.buscarDadosUsuarios()
    },

    esconderModal() {
      this.mostrarModal = !this.mostrarModal
    },

    async retornaRegioes() {
      await this.$http.get(this.$api.RegionaisBusca).then(res => {
        this.regioesSelect = res.data
      })
    },

    async retornaPerfis() {
      await this.$http.get(this.$api.PerfilBusca, { params: { tipoPerfil: 1 } }).then(res => {
        this.perfisSelect = res.data
      })
    },

    async retornaModulos() {
      await this.$http.get(this.$api.ModulosBusca).then(res => {
        this.modulosSelect = res.data
      })
    },

    marcarOuDesmarcarTodos() {
      let usuarios = this.tabela.items

      if (this.selecionarTodos) {
        if (this.getPortalUsuariosSesiAtivoAdminMasterAtualizar) {
          usuarios = usuarios.filter(item => item.id_usuario && perfisPermitidosAdminMaster.includes(item.perfil[0].id_perfil))
        }

        if (this.getPortalUsuariosSesiAtivoAdminModuloAtualizar) {
          usuarios = usuarios.filter(item => item.id_usuario && perfisPermitidosAdminModulo.includes(item.perfil[0].id_perfil))
        }

        this.usuariosAtivarInativarStatus = getArrayAttr(usuarios, 'id_usuario')
      } else {
        this.usuariosAtivarInativarStatus = []
      }
    },

    usuarioPodeSerAtivadoInativado(usuario) {
      if (!this.usuarioPodeAtivarInativar) {
        return false
      }

      if (this.getPortalUsuariosSesiAtivoAdminMasterAtualizar) {
        return podeAtivarInativarPorAdminMaster(usuario)
      }

      if (this.getPortalUsuariosSesiAtivoAdminModuloAtualizar) {
        return podeAtivarInativarPorAdminModulo(usuario)
      }

      return false
    },

    alterarStatusUsuariosEmMassa() {
      const modal = {
        titulo: 'Tem certeza que deseja alterar estes usuários?',
        texto:
          'Essa ação irá afetar o acesso destes usuários ao Cliente SESI + Saúde. Usuários ativos serão inativados e seus acessos suspensos. Usuários inativos serão ativados e seus acessos serão permitidos.',
        cor: '#FF7C02',
        textoBotao: 'Sim, alterar',
      }

      this.alterarStatusUsuario(this.usuariosAtivarInativarStatus, modal)
    },

    alterarStatusUsuarioUnico({ ativo, id_usuario }) {
      let modal
      if (ativo) {
        modal = {
          titulo: 'Tem certeza que deseja inativar?',
          texto:
            'Ao inativar um ou mais usuários, eles não terão mais permissão de acessar o Cliente SESI + Saúde.',
          cor: '#FF7C02',
          textoBotao: 'Sim, inativar',
        }
      } else {
        modal = {
          titulo: 'Tem certeza que deseja ativar?',
          texto:
            'Ao ativar um ou mais usuários, eles poderão acessar</br> novamente o Cliente SESI + Saúde.',
          cor: '#36BA00',
          textoBotao: 'Sim, ativar',
        }
      }

      this.alterarStatusUsuario([id_usuario], modal, true)
    },

    alterarStatusUsuario(usuariosId, modal, acaoUnica = false) {
      modalGenericModel(
        modal.titulo,
        modal.texto,
        modal.cor == '#36BA00' ? require('@/assets/custom-icons/cora-icons/warning.png')
        : require('@/assets/custom-icons/cora-icons/warning-red-icon.png'),
        modal.textoBotao,
        'Cancelar'
      ).then(result => {
        if (result.value) {
          this.inverterStatusUsuarios(usuariosId)
        } else if (acaoUnica) {
          this.tabela.items = []
          this.buscarDadosUsuarios()
        }
      })
    },

    async inverterStatusUsuarios(usuariosId) {
      this.loading = true

      await this.$http
        .put(this.$api.ativarInativarUsuarioAdmin, { usuariosId })
        .then(response => {
          if (response.status === 200) {
            this.selecionarTodos = false
            this.buscarDadosUsuarios()
          }
        })
        .catch(error => {
          this.handleError(error.response)
        })

      this.loading = false
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404

      if (errors) {
        return modalGenericModel(
          response.data.error.message,
          '',
          require('@/assets/custom-icons/cora-icons/warning.png'),
          'Ok'
        )
      }

      modalGenericModel(
        'Não foi possível realizar a sua solicitação. Entre em contato com o suporte',
        '',
        require('@/assets/custom-icons/cora-icons/warning.png'),
        'Ok'
      )
    },

    openModal(mode) {
      this.formMode = mode
      this.mostrarModal = true
    },

    openModalEdit({ id_usuario }) {
      this.chooseUser = { id: id_usuario }

      this.openModal('update')
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/custom/grupo.scss";
</style>
