import perfis from '@core/utils/perfis'

export const perfisPermitidosAdminMaster = [
  perfis.USUARIO_ADMIN_MODULO,
  perfis.OPERADOR,
]

export const perfisPermitidosAdminModulo = [
  perfis.OPERADOR,
]

export const podeAtivarInativarPorAdminMaster = usuario => perfisPermitidosAdminMaster.includes(usuario.perfil[0].id_perfil)

export const podeAtivarInativarPorAdminModulo = usuario => perfisPermitidosAdminModulo.includes(usuario.perfil[0].id_perfil)
